/* Colors */
$color-gradient: linear-gradient(
  135deg,
  rgba(0, 176, 238, 1) 0%,
  rgba(85, 108, 223, 1) 100%
);
$color-foreground: #001c3e;
$color-background: #f2f2f2;
$color-elevation: #f4faff;

/* Typography */
$type-font-primary: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
$type-regular: 400;
$type-semibold: 600;
$type-bold: 700;
$type-extrabold: 800;
$type-black: 900;

/* Layout */
$page-horizontal-padding: 40px;
$page-horizontal-padding-mobile: 24px;
$page-width-limit: 1240px;

body {
  margin: 0;
  background-color: white;
  color: $color-foreground;
  font-family: $type-font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  img {
    max-width: 100%;
  }

  a {
    color: $color-foreground;
    text-decoration: none;
  }
}
