@import "common/styles/_base.scss";

.home {
  text-align: left;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:before {
    top: 0;
    left: 0;
    width: 70vw;
    height: 120vh;
    background-image: url("../assets/images/gradient-blob.jpg");
    z-index: -11;
  }

  &:after {
    top: 24vh;
    right: 0;
    width: 100vw;
    height: 140vh;
    background-image: url("../assets/images/secondary-blob.png");
    background-position: right 0 top;
    background-size: cover;
    z-index: -12;
  }

  .page-section {
    width: 100%;
    max-width: $page-width-limit;
    margin: 0 auto;
    padding: 100px $page-horizontal-padding;
    box-sizing: border-box;

    .cta {
      padding: 20px 32px;
      border-radius: 4px;
      font-weight: $type-black;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      font-family: "Mulish";
      border: 0;
      cursor: pointer;
    }
  }

  .hero {
    display: flex;
    align-items: center;
    height: 100vh;
    margin-bottom: 0;
    color: $color-background;

    .hero-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    .introduction {
      max-width: 45%;

      .headline {
        font-weight: $type-black;
        font-size: 64px;
        line-height: 100%;
        letter-spacing: -1.2px;
        margin-left: -1px;
        margin-bottom: 32px;
        margin-top: 0;
      }

      .blurb {
        font-size: 20px;
        line-height: 160%;
        margin-bottom: 112px;

        b {
          font-weight: $type-black;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: 2px;
            width: 97%;
            height: 10px;
            border-radius: 24px;
            background: linear-gradient(
              135deg,
              rgba(85, 108, 223, 1) 0%,
              rgba(0, 176, 238, 1) 100%
            );
            z-index: -1;
          }
        }
      }

      .free-consultation {
        position: relative;
        background: transparent;
        border: 1px solid $color-background;
        color: $color-foreground;
        transition: all 0.3s ease;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 4px;
          transform-origin: right center;
          transition: all 0.2s ease;
        }

        &:before {
          z-index: -1;
          background: $color-background;
        }

        &:after {
          z-index: -2;
          background: linear-gradient(
            135deg,
            rgba(85, 108, 223, 1) 0%,
            rgba(0, 176, 238, 1) 100%
          );
        }

        &:hover {
          &:before {
            transform: scaleX(0);
          }
          color: $color-background;
        }
      }
    }

    .illustration {
      max-width: 42%;
      transform: translateY(12px);
    }
  }

  .services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "summary services"
      "contact services";
    column-gap: 80px;

    .section-summary {
      grid-area: summary;

      .title {
        font-size: 56px;
        font-weight: $type-bold;
        line-height: 100%;
        letter-spacing: -1px;
        margin-left: -1px;
        margin-bottom: 32px;
      }

      .description {
        font-size: 20px;
        line-height: 160%;
      }
    }

    .services-list {
      grid-area: services;

      details {
        position: relative;
        margin-bottom: 24px;
        padding: 0 24px;
        opacity: 0.4;
        overflow: hidden;
        transform: translateZ(0);
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:not([open]) {
          height: 50px;
        }

        &[open] {
          height: 134px;
          background: white;
          box-shadow: 4px 12px 80px rgba(0, 0, 0, 0.08);
          border-radius: 8px;
          opacity: 1;

          p,
          img {
            animation: fadeIn 1s ease;
          }
        }

        summary {
          position: relative;
          font-size: 24px;
          font-weight: $type-bold;
          line-height: 100%;
          letter-spacing: -0.5px;
          margin-bottom: 12px;
          padding-top: 24px;
          padding-left: 35px;
          outline: none;

          &::-webkit-details-marker {
            display: none;
          }

          &:first-of-type {
            list-style-type: none;
          }

          &:before,
          &:after {
            content: "";
            position: absolute;
            top: calc(50% + 12px);
            transform: translateY(-50%);
            width: 3px;
            height: 8px;
            background: $color-gradient;
            border-radius: 2px;
            margin-right: 5px;
          }

          &:before {
            left: 0;
            width: 3px;
          }

          &:after {
            left: 5px;
            width: 6px;
          }
        }
        p {
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0px;
          padding-left: 35px;
          padding-bottom: 24px;
          max-width: 64%;
          margin: 0;
        }

        img {
          position: absolute;
          bottom: 16px;
          right: 24px;
          width: 80px;
          height: 80px;
          transition: opacity 0.3s ease;
        }
      }
    }

    .contact-link {
      grid-area: contact;
      align-self: end;
      width: fit-content;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.6;
      }

      .contact-link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .label {
          font-weight: $type-extrabold;
          font-size: 20px;
          line-height: 160%;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          margin-right: 8px;
        }

        .line {
          display: inline-block;
          width: 24px;
          height: 2px;
          background-color: $color-foreground;
        }

        .arrow {
          border: solid $color-foreground;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          margin-left: -8px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .qualities {
    .qualities-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 120px;
      padding: 0;

      li {
        list-style-type: none;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: -40px;
          left: -40px;
          width: calc(100% + 80px);
          height: calc(100% + 80px);
          background-color: $color-elevation;
          box-shadow: 4px 12px 80px rgba(0, 0, 0, 0.08);
          border: 1px dashed $color-foreground;
          border-radius: 8px;
          opacity: 0;
          transform: scale(0.96);
          transition: all 0.3s ease;
          z-index: -1;
        }

        &:hover {
          &:before {
            opacity: 1;
            transform: scale(1);
          }
        }

        .illustration {
          margin-bottom: 40px;
          img {
            max-height: 180px;
          }
        }

        .bullet-wrapper {
          margin-bottom: 16px;
          .bullet {
            display: inline-block;
            height: 8px;
            background: $color-gradient;
            border-radius: 2px;

            &.small {
              width: 3px;
              margin-right: 2px;
            }

            &.large {
              width: 16px;
            }
          }
        }

        .title {
          font-size: 40px;
          font-weight: $type-bold;
          line-height: 100%;
          letter-spacing: -0.8px;
          margin-bottom: 24px;
        }

        .description {
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0.6px;
        }
      }
    }
  }

  .contact {
    max-width: unset;
    background-color: $color-elevation;
    padding: 144px 0;

    .contact-wrapper {
      width: 100%;
      max-width: $page-width-limit;
      margin: 0 auto;
      padding-left: $page-horizontal-padding;
      padding-right: $page-horizontal-padding;
      box-sizing: border-box;

      .title {
        display: inline-block;
        font-size: 56px;
        font-weight: $type-bold;
        line-height: 100%;
        letter-spacing: -1px;
        margin: 0 64px 80px -1px;
      }

      .quick-contact {
        display: inline-block;
        transform: translateY(-5px);

        .item {
          margin-right: 64px;
          transition: opacity 0.3s ease;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            opacity: 0.4;
          }

          .icon {
            display: inline-block;
            width: 32px;
            height: 32px;
            vertical-align: middle;
            margin-right: 16px;
          }

          .value {
            font-size: 20px;
            font-weight: $type-semibold;
            line-height: 160%;
            letter-spacing: 0.4px;
            vertical-align: middle;
          }
        }
      }

      .contact-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
          "name message"
          "phone message"
          "email consent";
        gap: 40px;

        .item {
          position: relative;

          label {
            position: absolute;
            top: -8px;
            left: 12px;
            padding: 0 6px;
            font-size: 16px;
            font-weight: $type-semibold;
            letter-spacing: 0.3px;
            background-color: $color-elevation;
          }

          input:not(.cta),
          textarea {
            box-sizing: border-box;
            width: 100%;
            font-size: 22px;
            text-transform: capitalize;
            color: $color-foreground;
            background-color: transparent;
            border: 1px solid $color-foreground;
            border-radius: 4px;
            padding: 20px 16px 14px;
          }

          &.name {
            grid-area: name;
          }
          &.phone {
            grid-area: phone;
          }
          &.email {
            grid-area: email;
            .email-input-box {
              text-transform: none;
            }
          }
          &.message {
            grid-area: message;

            textarea {
              height: 100%;
              resize: none;
              font-family: $type-font-primary;
            }
          }
          &.consent {
            grid-area: consent;
            display: grid;
            grid-template-columns: auto auto min-content;
            justify-content: start;
            align-items: center;

            input[type="checkbox"] {
              width: min-content;
              margin-right: 8px;
              background-color: transparent;
            }

            input[type="submit"] {
              background: $color-foreground;
              color: $color-background;
              font-size: 18px;
              font-weight: $type-extrabold;
              letter-spacing: 1px;
              padding: 20px 64px;
              transition: opacity 0.3s ease;

              &:hover {
                opacity: 0.8;
              }
            }

            label {
              position: static;
              font-weight: $type-regular;
              line-height: 140%;
              letter-spacing: 0.3px;
              margin-right: 32px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &:before {
      width: 100vw;
      height: 96vh;
      background-image: url("../assets/images/gradient-blob-mobile.jpg");
      background-size: cover;
      background-position: right;
    }

    &:after {
      width: 100vw;
      height: 216vh;
      background-image: url("../assets/images/secondary-blob-mobile.png");
      background-size: cover;
      background-position: center;
    }

    .page-section {
      max-width: unset;
      padding: 64px $page-horizontal-padding-mobile;

      .cta {
        padding: 12px 16px;
        font-size: 14px;
      }
    }

    .hero {
      height: 90vh;

      .hero-wrapper {
        display: block;
      }

      .introduction {
        max-width: unset;

        .headline {
          font-size: 36px;
          line-height: 100%;
          letter-spacing: -0.7px;
          margin-left: -1px;
          margin-bottom: 24px;
          margin-top: 0;
        }

        .blurb {
          font-size: 14px;
          line-height: 144%;
          margin-bottom: 64px;

          b {
            &:before {
              top: 7px;
              height: 7px;
            }
          }
        }

        .free-consultation {
        }
      }

      .illustration {
        display: none;
      }
    }

    .services {
      grid-template-columns: auto;
      grid-template-areas:
        "summary"
        "services"
        "contact";
      row-gap: 32px;

      .section-summary {
        .title {
          font-size: 32px;
          font-weight: $type-bold;
          line-height: 100%;
          letter-spacing: -0.6px;
          margin-left: -1px;
          margin-bottom: 24px;
        }

        .description {
          font-size: 16px;
          line-height: 144%;
        }
      }

      .services-list {
        details {
          padding: 0 16px;
          margin: 0 -16px 16px -16px;

          &[open] {
            height: 128px;
            border-radius: 4px;
          }

          summary {
            font-size: 20px;
            font-weight: $type-bold;
            line-height: 100%;
            letter-spacing: -0.4px;
            margin-bottom: 12px;
            padding-top: 16px;
            padding-left: 23px;

            &:before,
            &:after {
              top: calc(50% + 9px);
              margin-right: 2px;
            }
          }

          p {
            font-size: 16px;
            line-height: 128%;
            padding-left: 0px;
            padding-bottom: 16px;
            max-width: 80%;
            margin: 0;
          }

          img {
            bottom: 12px;
            right: 16px;
            width: 40px;
            height: 40px;
          }
        }
      }

      .contact-link {
        margin-top: 24px;
        .contact-link-wrapper {
          .label {
            font-size: 16px;
            line-height: 144%;
            letter-spacing: 0.3px;
          }
        }
      }
    }

    .qualities {
      .qualities-list {
        width: 100%;
        grid-template-columns: auto;
        row-gap: 80px;

        li {
          display: flex;
          flex-direction: column;

          &:before {
            content: none;
          }

          &:nth-child(2) {
            justify-self: end;
            text-align: right;

            .title {
              align-self: flex-end;
            }
          }

          .illustration {
            margin-bottom: 24px;

            img {
              // max-height: 120px;
              max-width: 60vw;
            }
          }

          .bullet-wrapper {
            margin-bottom: 12px;
          }

          .title {
            font-size: 26px;
            font-weight: $type-bold;
            line-height: 100%;
            letter-spacing: -0.5px;
            margin-bottom: 12px;
            max-width: 180px;
          }

          .description {
            font-size: 14px;
            line-height: 144%;
            letter-spacing: 0.3px;
            max-width: 240px;
          }
        }
      }
    }

    .contact {
      // padding: 80px;

      .contact-wrapper {
        max-width: unset;
        padding-left: 0;
        padding-right: 0;

        .title {
          font-size: 32px;
          font-weight: $type-bold;
          line-height: 100%;
          letter-spacing: -0.6px;
          margin-left: -1px;
          margin-bottom: 56px;
        }

        .quick-contact {
          display: block;
          margin-bottom: 64px;

          .item {
            display: block;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            .icon {
              svg {
                max-width: 26px;
              }
            }
            .value {
              font-size: 16px;
              font-weight: $type-semibold;
              line-height: 144%;
              letter-spacing: 0.3px;
            }
          }
        }

        .contact-form {
          grid-template-columns: auto;
          grid-template-rows: auto auto auto auto auto;
          grid-template-areas:
            "name"
            "phone"
            "email"
            "message"
            "consent";
          row-gap: 24px;

          .item {
            &.consent {
              grid-template-columns: auto;

              label {
                font-size: 13px;
                font-weight: $type-semibold;
                letter-spacing: 0.2px;
              }

              input[type="checkbox"],
              input[type="checkbox"] ~ label {
                grid-row: 1;
                grid-column: 1;
              }

              input[type="checkbox"] ~ label {
                margin-left: 19px;
              }

              input:not(.cta),
              textarea {
                padding: 16px 16px 12px;
              }

              input[type="submit"] {
                grid-column: 1 / 2;
                width: 100%;
                font-size: 14px;
                line-height: 100%;
                padding: 16px 16px;
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
