@import-normalize;
@import "common/styles/_base.scss";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800;900&display=swap");

@font-face {
  /* font-family: "Mulish";
  src: local("Mulish"), url(./assets/fonts/mulish.ttf) format("truetype");
  font-weight: 1 999; */
}

html {
  scroll-behavior: smooth;

  /* @media screen and (max-width: 1200px) {
    zoom: 0.8;
  } */
}
