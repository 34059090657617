@import "common/styles/_base.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;

  &.sticky {
    background-color: rgba(255, 255, 255, 0.96);
    box-shadow: 0 24px 80px rgba(0, 0, 0, 0.08);

    .wrapper {
      padding: 24px $page-horizontal-padding;
    }
  }

  &:not(.sticky) {
    .wrapper {
      .logo {
        svg > path {
          fill: $color-background;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 56px $page-horizontal-padding;
    box-sizing: border-box;
    max-width: $page-width-limit;
    transform: translateZ(0);
    transition: all 0.3s ease;

    .logo {
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.6;
      }
    }

    .navigation {
      .item {
        font-size: 13px;
        font-weight: $type-semibold;
        line-height: 140%;
        letter-spacing: 2px;
        margin-right: 40px;
        text-transform: uppercase;
        transition: all 0.3s ease;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background: $color-gradient;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    &.sticky {
      .wrapper {
        padding: 16px $page-horizontal-padding-mobile;

        .navigation {
          .item {
            color: $color-foreground;
          }
        }
      }
    }

    .wrapper {
      padding: 32px $page-horizontal-padding-mobile;

      .logo {
        svg {
          max-width: 56px;
        }
      }

      .navigation {
        .item {
          font-size: 12px;
          font-weight: $type-semibold;
          line-height: 140%;
          letter-spacing: 1px;
          margin-right: 24px;
          color: $color-background;
        }
      }
    }
  }
}
